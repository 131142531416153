var HashMap = require('hashmap');

function generatePaymentMethodsList(self){
    var paymentMethodsList = new HashMap();
    paymentMethodsList.set("android", [
        {
            "id": 1,
            "methodTitle": self.$t('sips'),
            "methodName": "SIPS",
            "fileName": "payment-card",
            "favorite": true,
            "enable": true
        },
        {
            "id": 2,
            "methodTitle": self.$t('valinaPaymentMethod'),
            "methodName": "VALINA",
            "fileName": "terminal",
            "favorite": false,
            "enable": true
        },
        {
            "id": 4 ,
            "methodTitle": self.$t('googlePayMethodLabel'),
            "methodName": "GooglePay",
            "fileName": "gPay",
            "favorite": false,
            "enable": true
        },
        {
            "id": 3,
            "methodTitle": self.$t('worldlinePayMethodLabel'),
            "methodName": "W3C",
            "fileName": "wl-symbol-logo",
            "favorite": false,
            "enable": false
        }
    ]);

    paymentMethodsList.set("iphone", [
        {
            "id": 1,
            "methodTitle": self.$t('sips'),
            "methodName": "SIPS",
            "fileName": "payment-card",
            "favorite": true,
            "enable": true
        },
        {
            "id": 2,
            "methodTitle": self.$t('valinaPaymentMethod'),
            "methodName": "VALINA",
            "fileName": "terminal",
            "favorite": false,
            "enable": true
        },
        {
            "id": 4 ,
            "methodTitle": self.$t('applePayMethodLabel'),
            "methodName": "ApplePay",
            "fileName": "applePay",
            "favorite": false,
            "enable": true
        },
        {
            "id": 3,
            "methodTitle": self.$t('worldlinePayMethodLabel'),
            "methodName": "W3C",
            "fileName": "wl-symbol-logo",
            "favorite": false,
            "enable": false
        }
    ]);

    paymentMethodsList.set("other", [
        {
            "id": 1,
            "methodTitle": self.$t('sips'),
            "methodName": "SIPS",
            "fileName": "payment-card",
            "favorite": true,
            "enable": true
        },
        {
            "id": 2,
            "methodTitle": self.$t('valinaPaymentMethod'),
            "methodName": "VALINA",
            "fileName": "terminal",
            "favorite": false,
            "enable": true
        },
        {
            "id": 4 ,
            "methodTitle": self.$t('googlePayMethodLabel'),
            "methodName": "GooglePay",
            "fileName": "gPay",
            "favorite": false,
            "enable": true
        },
        {
            "id": 3,
            "methodTitle": self.$t('worldlinePayMethodLabel'),
            "methodName": "W3C",
            "fileName": "wl-symbol-logo",
            "favorite": false,
            "enable": false
        }
    ]);
    return paymentMethodsList;
}


export function getPaymentMethodsList(self) {
    var paymentMethodsList = generatePaymentMethodsList(self);
    var deviceType = localStorage.getItem('IEC_deviceType')
    return paymentMethodsList.get(deviceType);
}

export function enablePaymentMethod(fileName, self){
    var allPaymentMethod = getPaymentMethodsList(self);
    var paymentMethod;

    allPaymentMethod.some(value => {
        if(value.fileName.toLowerCase() == fileName.toLowerCase()){
            paymentMethod = value;
            return true;
        }
    });

    if(paymentMethod.enable){
        return true;
    }else{
        return false;
    }

}

export function getContext(merchantId, transactionId, content, callback) {

    var params = {
        merchantId: merchantId,
        transactionId: transactionId,
        content: content
    };
    var request = new XMLHttpRequest();

    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {

            var response = JSON.parse(request.response);
            callback(response);

        }
    };

    request.open('POST', 'https://rd.atosworldline.com/c4p/context', true);
    request.setRequestHeader('Content-type', "application/json;charset=UTF-8");
    request.send(JSON.stringify(params));

}
